import * as moment from 'moment'
import 'moment/locale/vi'
import _ from 'lodash'
import {mmyy} from '../dateTime/handleWeek'
import { DAILY_TUITION, DAILY_MEAL, SUMMER_TUITION, BREAKFAST_FEE, INFRASTRUCTURE, CLEAN_FUEL, SERVICE_BOARDING, SERVICE_SEVEN_DAY, LEARN_LANGUAGUE,NUM_OF_MEAL_MONTH,NUM_OF_MEAL_7 } from '../../contant/Gov/cost'
import {ArrNumDate,sumTotal,HandleAllArrListRegisSeven} from '../countEat'
import {FilterItemTransferByClassByMonthPaymentListTotal} from '../countEat/transfer'

export const showDateShortName=(name)=>{
  switch (name) {
    case "thứ hai":
      return "hai"
    case "thứ ba":
      return "ba"
    case "thứ tư":
      return "tư"
    case "thứ năm":
      return "năm"
    case "thứ sáu":
      return "sáu"
    case "thứ bảy":
      return "bảy"
    case "chủ nhật":
      return "cn"  
    
      default: return null
  }
}
export const ConvertOriginDateMonth=(item)=>{
  let value = item.split('/')
  let dd = '02'
  let mm = value[0]
  let yy = value[1]
  return `${yy}/${mm}/${dd}`
}

export const handleConvertDDMMYY=(item)=>{ 
  if(item===null||item===""){
    return ""
  } 
  if(item!==""||item!==null||typeof(item)!==Object){
    let newItem = item.split('/')
    return `${newItem[2]}/${newItem[1]}/${newItem[0]}`
  }      
  if(typeof(item)===Object){
    return item
  }
}

const forEachArr_mm =(mm,m,Y,list)=>{
  let a =[] 
  for (let i = 1; i <= mm ; i++) {
    let date = `${String(Y)}/${String(m)}/${String(i)}`    
    let result ={
     dateName:showDateName(date),
     numWeek:numWeek(date),
     nameWeek:nameWeek(date),
     date:`${handleMM(i)}/${handleMM(m)}/${String(Y)}`    
   } 
   a.push(result)   
  }
 let arr_notSunday = a.filter(item=>item.dateName!=='chủ nhật')
 let mmYY = `${(handleMM(m))}/${String(Y)}`
 let MonthFilted =filterMonth(mmYY,list)
return handleExitDateInMonth(arr_notSunday,MonthFilted)
}

//xac dinh so ngay trong1 tuan c
export const sortWeek = (value,list,m,y)=>{
 let a =  [...new Set(value.map(item=>item.numWeek))]
 let newSelectWeek = a.map(item=>value.filter(i=>i.numWeek===item))
 let arrHandleWeek = resultHandleWeek(m,y,list)
 let arrWeek = handleDefineWeek(newSelectWeek)  
 if(arrWeek[3].length!==6){
    let a= handleWeekLess4(arrWeek,arrHandleWeek)   
    return a
 }else{
   return arrWeek
 } 
}

const handleWeekLess4=(arrWeek,arrHandleWeek)=>{
  let resultFind =[]  
    if(arrWeek[3].length<6){      
      let find = arrWeek[3][arrWeek[3].length-1].numWeek  
      resultFind= arrHandleWeek.filter(item=>item.numWeek===find) 
    }  
   resultFind = _.orderBy(resultFind,'nameWeek','asc')
   let newArr =[arrWeek[0],arrWeek[1],arrWeek[2],resultFind]   
   return newArr  
}

const resultHandleWeek = (m,y,list)=>{   
    let arr = (mmyy(m,y)).map(item=>numberDateOfMonth(item.m,item.y,list))  
      arr = _.union(arr[0],arr[1],arr[2])
     return arr
}

const handleDefineWeek =(arrWeek)=>{ 
let check = numberDefinedWeek(arrWeek) 
  if(check[0]!==6&&check.length===6&&check[5]!==6){
    arrWeek.shift()
    arrWeek.pop()
    return[...arrWeek]
  }
  if(check[0]!==6 && check.length===5&&(check[4]!==6||check[4]===6)){  
      arrWeek.shift()    
      return [...arrWeek]    
  } 
  
  if(check.length===4&&check[0]===6&&check[3]===6){  
    return [...arrWeek]
  }
  if(check[0]===6 && check.length!==4){
    arrWeek.pop()
    return [...arrWeek]
  }
 }

const numberDefinedWeek =(arr)=>{
  return arr.map(item=>Object.keys(item).length)
}
export const FindClassNotRegisSevenDay=(arrRegis,arrClass)=>{
   let NewArrRegis =  arrRegis.map(item=>{
        let index = arrClass.findIndex(value=>value._id===item.idClass)
        if(index>=0){
          return{
            ...item,
            ...arrClass[index]
          }
        }else{
          return {...item}
        }
    })
    let ArrRegisNameClass = NewArrRegis.map(v=>v.nameClass)
    let ArrNewClass= arrClass.map(v=>v.nameClass)   
    return _.difference(ArrNewClass,ArrRegisNameClass)   
}

//xac dinh so ngay trong1 tuan c
const handleExitDateInMonth = (arr_date,arr_meal)=>{  
    return arr_date.map(item=>{
       let index = arr_meal.findIndex(value=>showDDMMYY(value.dateMenu)===item.date)
       if(index>=0){
          return {...item,
                  ...arr_meal[index]
                }          
       }else{
          return  {...item}
       }     
     })      
}

export const handleMM = (m)=>{  
   let str = String(m)     
    if(str.length===1){
      return ('0'+m)      
    }else {
      return m
    }   
}
const filterMonth =(mmYY,list)=>{  
   return list.filter(item=>showMMYY(item.dateMenu)===mmYY)     
}

export const handleDateMonth=(date)=>{
  let d    = '02'
  let M    = showMM(date)  
  let Y    = showYYYY(date)
  return `${String(Y)}/${String(M)}/${String(d)}`
}
export const showDateName = (value)=>{
   return moment(value).local('vi').format('dddd')
}
export const showDDMM = (value)=>{
  return moment(value).local('vi').format('DD/MM')
}

export const numWeek = (value)=>{ //so tuan trong nam
  return moment(value).local('vi').format('ww')
}
export const nameWeek = (value)=>{ //ten cac ngay trong tuan
  return moment(value).local('vi').format('e')
}
export const showDD = (value)=>{
  return moment(value).local('vi').format('DD')
}
export const showMM = (value)=>{
  return moment(value).local('vi').format('MM')
}
export const showYYYY = (value)=>{
  return moment(value).local('vi').format('YYYY')
}
export const showDDMMYY = (value)=>{
  return moment(value).local('vi').format('DD/MM/YYYY')
}
export const showMMYY = (value)=>{
  return moment(value).local('vi').format('MM/YYYY')
}

export const showYYMMDD = (value)=>{
  return moment(value).local('vi').format('YYYY-MM-DD')
}

export const showDefaultDate = (value)=>{
  let str =  String(value)
  let dd = str.substr(0,2) 
  let mm = str.substr(3,2) 
  let yy = str.substr(6,4) 
  let v = yy+'-'+mm+'-'+dd
  return v
}

export const handleMMYY = (value)=>{
  let str =  String(value)  
  let mm = str.substr(3,2) 
  let yy = str.substr(6,4) 
  let v = mm+'/'+yy
  return v
}

export const convertDDMMYY =(value)=>{
 return  moment(value).local('vi').format('DD/MM/YYYY')      
}


export const numberDateOfMonth =(_m,_y,list)=>{
   if((_m===1)||(_m===3)||(_m===5)||(_m===7)||(_m===8)||(_m===10)||(_m===12)){    
    return  forEachArr_mm(31,_m,_y,list)
  }else if((_m===4)||(_m===6)||(_m===9)||(_m===11)){      
    return  forEachArr_mm(30,_m,_y,list)
  }else if((_y%4===0)&&(_m===2)){    
    return forEachArr_mm(29,_m,_y,list)
  }else if((_y%4!==0&&(_m===2))){   
    return forEachArr_mm(28,_m,_y,list)
  }  
}



export const numberDate =(_m,_y)=>{
  let number = 0
  _m = +_m
  _y = +_y
  if((_m===1)||(_m===3)||(_m===5)||(_m===7)||(_m===8)||(_m===10)||(_m===12)){    
   return  number = 31
 }else if((_m===4)||(_m===6)||(_m===9)||(_m===11)){      
   return  number = 30
 }else if((_y%4===0)&&(_m===2)){    
   return number = 29
 }else if((_y%4!==0&&(_m===2))){   
   return number = 28
 }  
}

export const handleCheckExistDate =(arr,item)=>{  
  let newItem = {date:showDDMMYY(item.date)}
  return arr.findIndex(v=>showDDMMYY(v.dateMenu)===newItem.date)
}
export const handleCheckExistDateInput =(arr,item)=>{  
  let newItem = {date:showDDMMYY(item)}
  return arr.findIndex(v=>showDDMMYY(v.dateMenu)===newItem.date)
}
export const handleCheckExistFood =(arr,item)=>{
  let newItem = {date:showDDMMYY(item.dateNow)}
  return arr.findIndex(v=>showDDMMYY(v.dateNow)===newItem.date)
}
export const handleCheckExistFoodDateNow =(arr,item)=>{
  let newItem = {date:showDDMMYY(item)}
  return arr.findIndex(v=>showDDMMYY(v.dateNow)===newItem.date)
}

export const handleCheckExistFoodClass =(arr,item)=>{    
  return arr.findIndex(v=>
    (showDDMMYY(v.dateNow)   === item.dateNow      &&
      v.idYearSchool       === item.idYearSchool &&
      v.idClass            === item.idClass      &&
      v.idBlockClass       === item.idBlockClass &&
      v.userId             === item.userId
  ))
}
export const FindValueCheckDateFoodClassDateInput =(arr,item)=>{  
  return arr.filter(v=>
    (showDDMMYY(v.dateNow) === item.dateNow      &&
      v.idYearSchool       === item.idYearSchool &&
      v.idClass            === item.idClass      &&
      v.idBlockClass       === item.idBlockClass &&
      v.userId             === item.userId
  ))
}

export const getDateMeal =(arr,item)=>{
  //console.log(item)
  let newItem = {date:showDDMMYY(item.date)}

  return arr.filter(v=>showDDMMYY(v.dateMenu)===newItem.date)
}

export const SelectMonthYear = (item)=>{
  let DD = {
    M: +(moment(item).local('vi').format('MM')),
    Y: +(moment(item).local('vi').format('YYYY'))
  }
}

export const HandleListStudentInClass = (ListOriginStudent,ItemStudent,YearSchool,CurrentDate)=>{
        let FindPrvM           = handlePrvMonth(CurrentDate)        
        let FilterCurentDate   =  ListOriginStudent.filter(v=>showMMYY(v.dateNow)===showMMYY(CurrentDate))
        let FilterSecondCurentDate = ListOriginStudent.filter(v=>showMMYY(v.dateNow)===FindPrvM)
        let Current       =  FilterCurentDate.filter(v=>v.idBlockClass===ItemStudent.idBlockClass&&v.idYearSchool===YearSchool._id&&v.idClass===ItemStudent._id)
        let SecondCurrent =  FilterSecondCurentDate.filter(v=>v.idBlockClass===ItemStudent.idBlockClass&&v.idYearSchool===YearSchool._id&&v.idClass===ItemStudent._id)
         let item ={
          Current,SecondCurrent
        }
        return item
}

export const handleFindCost =(arrPrice)=>{
  let ObjectPrice ={DailyTui:0,PriceMeal:0,Summer:0,Breaf:0,InFras:0,Clean:0,NumEat:0,NumEat7:0,Boarding:0,Seven:0,LeanLg:0}
  let ResultCost= arrPrice.map(v=>{     
     if(v.name===DAILY_TUITION){   
      let Item ={
        price:v.price,
        name:v.name
      }      
      ObjectPrice.DailyTui=v.price
      return  Item
     }    
     if(v.name===DAILY_MEAL){ 
      let Item ={
        price:v.price,
        name:v.name
      }       
       ObjectPrice.PriceMeal=v.price
       return Item
     }
     if(v.name===SUMMER_TUITION){   
      let Item ={
        price:v.price,
        name:v.name
      }    
     ObjectPrice.Summer=v.price
     return Item
    }
    if(v.name===BREAKFAST_FEE){
      let Item ={
        price:v.price,
        name:v.name
      }            
     ObjectPrice.Breaf=v.price
     return Item
    }
    if(v.name===INFRASTRUCTURE){  
      let Item ={
        price:v.price,
        name:v.name
      }             
       ObjectPrice.InFras=v.price
       return Item
    }     
    if(v.name===CLEAN_FUEL){   
      let Item ={
        price:v.price,
        name:v.name
      }       
      ObjectPrice.Clean=v.price
      return Item
    }     
    if(v.name===NUM_OF_MEAL_MONTH){
      let Item ={
        price:v.price,
        name:v.name
      }  
      ObjectPrice.NumEat=v.price
      return Item
    }     
    if(v.name===NUM_OF_MEAL_7){
      let Item ={
        price:v.price,
        name:v.name
      }  
      ObjectPrice.NumEat7=v.price
      return Item
    }     
    if(v.name===SERVICE_BOARDING){     
      let Item ={
        price:v.price,
        name:v.name
      }   
     ObjectPrice.Boarding=v.price
     return Item
    }     
    if(v.name===SERVICE_SEVEN_DAY){      
      let Item ={
        price:v.price,
        name:v.name
      }  
       ObjectPrice.Seven=v.price
       return Item
    }     
    if(v.name===LEARN_LANGUAGUE){      
      let Item ={
        price:v.price,
        name:v.name
      }  

       ObjectPrice.LeanLg=v.price
      return Item
   }
  })
    let Result = {ObjectPrice,ResultCost}   
    return Result
}
export const handleFindCostWithUpdateDailyTui =(arrPrice,FilterUpdateDailyTui)=>{
  let UpdateDailyTui = FilterUpdateDailyTui[0].priceDailyTui
  let ObjectPrice ={DailyTui:0,PriceMeal:0,Summer:0,Breaf:0,InFras:0,Clean:0,NumEat:0,NumEat7:0,Boarding:0,Seven:0,LeanLg:0}
  let ResultCost= arrPrice.map(v=>{     
     if(v.name===DAILY_TUITION){   
      let Item ={
        price:UpdateDailyTui,
        name:v.name
      }      
      ObjectPrice.DailyTui=UpdateDailyTui
      return  Item
     }    
     if(v.name===DAILY_MEAL){ 
      let Item ={
        price:v.price,
        name:v.name
      }       
       ObjectPrice.PriceMeal=v.price
       return Item
     }
     if(v.name===SUMMER_TUITION){   
      let Item ={
        price:v.price,
        name:v.name
      }    
     ObjectPrice.Summer=v.price
     return Item
    }
    if(v.name===BREAKFAST_FEE){
      let Item ={
        price:v.price,
        name:v.name
      }            
     ObjectPrice.Breaf=v.price
     return Item
    }
    if(v.name===INFRASTRUCTURE){  
      let Item ={
        price:v.price,
        name:v.name
      }             
       ObjectPrice.InFras=v.price
       return Item
    }     
    if(v.name===CLEAN_FUEL){   
      let Item ={
        price:v.price,
        name:v.name
      }       
      ObjectPrice.Clean=v.price
      return Item
    }     
    if(v.name===NUM_OF_MEAL_MONTH){
      let Item ={
        price:v.price,
        name:v.name
      }  
      ObjectPrice.NumEat=v.price
      return Item
    }     
    if(v.name===NUM_OF_MEAL_7){
      let Item ={
        price:v.price,
        name:v.name
      }  
      ObjectPrice.NumEat7=v.price
      return Item
    }     
    if(v.name===SERVICE_BOARDING){     
      let Item ={
        price:v.price,
        name:v.name
      }   
     ObjectPrice.Boarding=v.price
     return Item
    }     
    if(v.name===SERVICE_SEVEN_DAY){      
      let Item ={
        price:v.price,
        name:v.name
      }  
       ObjectPrice.Seven=v.price
       return Item
    }     
    if(v.name===LEARN_LANGUAGUE){      
      let Item ={
        price:v.price,
        name:v.name
      }  

       ObjectPrice.LeanLg=v.price
      return Item
   }
  })
    let Result = {ObjectPrice,ResultCost}   
    return Result
}

export const handlePrvMonth  = (date)=>{
  let Month = +(showMM(date))
  let Year  = +(showYYYY(date))
  if(Month===1){
    let MM = 12
    let YY = Year-1
    let date = `${String(YY)}/${String(MM)}`
    return showMMYY(date)
  }
  if(Month!==0&&Month!==1){
    let MM = Month-1    
    let date = `${String(Year)}/${String(MM)}`
    return showMMYY(date)
  }
}
export const handlePrvMonthTransfer  = (date)=>{
  let Month = +(showMM(date))
  let Year  = +(showYYYY(date))
  if(Month===1){
    let DD = 2
    let MM = 12
    let YY = Year-1
    return `${String(YY)}/${String(MM)}/${String(DD)}`
  }
  if(Month!==0&&Month!==1){
    let DD = 2
    let MM = Month-1        
    return `${String(Year)}/${String(MM)}/${String(DD)}`
  }
}

export const showPrvMonth =(date)=>{
  let  findTime = +(showMM(date))-1
  if(findTime===0){
    let Y = +(showYYYY(date))-1
    let YY = Y.toString()
   return `${'12'}`
  }
  return `${handleMM(findTime)}`
}

export const handleShowDefineTime=(date)=>{
  let  findTime = +(showMM(date))-1
  if(findTime===0){
    let Y = +(showYYYY(date))-1
    let YY = Y.toString()
   return `${'12'}/${YY}`
  }
  return `${handleMM(findTime)}/${showYYYY(date)}`
}



export const handleDateInRepo=(date)=>{
  let  dd = "01"
  let  findTime = +(showMM(date))-1
  if(findTime===0){
    let Y = +(showYYYY(date))-1
    let YY = Y.toString()
   return `${YY}/${'12'}/${dd}`
  }
  return `${showYYYY(date)}/${handleMM(findTime)}/${dd}`
}


// export const showCurrentDateInRepo=(date)=>{
//   let  dd = "01"
//   let  findTime = +(showMM(date))
//   if(findTime===1){
//     let Y = +(showYYYY(date))-1
//     let YY = Y.toString()
//    return findTime= `${YY}/${handleMM(findTime)}/${dd}`
//   }
//   return findTime = `${showYYYY(date)}/${handleMM(findTime)}/${dd}`
// }



export const TotalStudentCountEat=(arrCheckFood,ListClass,date)=>{    
  let filterArrCheckFood = arrCheckFood.filter(v=>showMMYY(v.dateNow)===showMMYY(date))
  if(filterArrCheckFood.length===0){
    return {
        result:[],
        sumTotal:0
    }
  }else{
     let result = ListClass.map(v=>{
    let nameClass = v.nameClass
    let FindSumStudentNotEat = filterArrCheckFood.filter(i=>i.idClass===v._id)
    let sumClass = SumItemClass(FindSumStudentNotEat)   
    let result   ={
      nameClass,
      sumClass
    }
    return result
  })
  let handleSum = result.map(v=>v.sumClass)
  let sumTotal = handleSum.reduce((x,y)=>x+y,0)
  return {result,sumTotal}
  } 
}
const SumItemClass=(arrCheckFood)=>{
  return (arrCheckFood.map(v=>v.nameStudentNotEat.length)).reduce((x,y)=>x+y,0)
}
export const EditPaymentListTotal=(arr,prices)=>{
//console.log(prices)
 return arr.map(v=>{
    let newItem={
      sumBoarding:v.result.arrSumResult.sumBoarding,
      sumBreaf:v.result.arrSumResult.sumBreaf,
      sumClean:v.result.arrSumResult.sumClean,
      sumDailyTui:v.result.arrSumResult.sumDailyTui,
      sumEatPrv:v.result.arrSumResult.sumEatPrv,
      sumInFras:v.result.arrSumResult.sumInFras,
      sumLearnLg:v.result.arrSumResult.sumLearnLg,
      sumNotEat:v.result.arrSumResult.sumNotEat,
      sumNumEat:v.result.arrSumResult.sumNumEat,
      sumNumEat7:v.result.NumStudentEat7*prices.NumEat7,
      sumNumEatUse:v.result.NumStudentEat7*prices.NumEat7+v.result.arrSumResult.sumNumEat-v.result.arrSumResult.sumEatPrv,
      sumPriceMeal:(v.result.NumStudentEat7*prices.NumEat7+v.result.arrSumResult.sumNumEat-v.result.arrSumResult.sumEatPrv)*prices.PriceMeal,
      sumSeven:v.result.NumStudentEat7*prices.NumEat7*prices.Seven,
      sumSum:v.result.arrSumResult.sumBoarding+v.result.arrSumResult.sumBreaf+v.result.arrSumResult.sumClean+v.result.arrSumResult.sumDailyTui+v.result.arrSumResult.sumInFras+v.result.arrSumResult.sumLearnLg+((v.result.NumStudentEat7*prices.NumEat7+v.result.arrSumResult.sumNumEat-v.result.arrSumResult.sumEatPrv)*prices.PriceMeal)+v.result.NumStudentEat7*prices.NumEat7*prices.Seven+v.result.arrSumResult.sumSummer,
      sumSummer:v.result.arrSumResult.sumSummer
    }
    let item ={
        nameClass:v.nameClass,
        result:{
          NumStudent:v.result.NumStudent,
          NumStudentEat7:v.result.NumStudentEat7,
          arrSumResult:newItem
        }
    }
    return item
  })  
}
//xu ly thong tin 1 lop hs trong thang
export const PaymentList = (arr,item,id,ListStudent,Prices,ListRegisSeven,ItemArrPrvStudent)=>{ //item la moc chon thoi gian    
  let ItemRegis = ListRegisSeven.filter(v=>v.idClass===id&&showMMYY(v.dateMonth)===showMMYY(item))
  let CurrentStudent = ListStudent.filter(v=>v.idClass===id)
  let result = ""
  let TimePrv           = handleShowDefineTime(item)  
  let ArrDay            = arr.filter(v=>showMMYY(v.dateNow)===showMMYY(item)) //moc thoi gian hien tai
  let ArrDayPrv         = arr.filter(v=>showMMYY(v.dateNow)===TimePrv)
  let ArrCurrentStudent = FindStudentNotEatCurrent(ArrDay,CurrentStudent,id) //hoc sinh khong an thang hien tai
  let ArrPrvStudent     = ItemArrPrvStudent.length===0?FindStudentNotEatCurrent(ArrDayPrv,CurrentStudent,id):ItemArrPrvStudent // hoc sinh khong an thang qua khu  
  ArrCurrentStudent     = findSumPrvInArrStudent(ArrCurrentStudent,ArrPrvStudent)  
  let FindArrStudent    = FindArrStudentSeven(ArrCurrentStudent,ItemRegis)
  let arrStudent        = findArrStudent(FindArrStudent,Prices)
  //lay 2 phan tu dau va cuoi mang xac dinh thoi gian
  let dateNowBegin      = ArrDay.length>0?showDDMMYY(ArrDay[0].dateNow):[]
  let dateNowEnd        = ArrDay.length>0?showDDMMYY(ArrDay[ArrDay.length-1].dateNow):[]  //xac dinh lop
  let arrSumResult      = SumPrices(arrStudent)
  return result = {
    dateBegin:dateNowBegin,
    dateEnd:dateNowEnd,
    arrStudent,
    arrSumResult,
    NumStudent:CurrentStudent.length,
    NumStudentEat7:ItemRegis.length!==0?ItemRegis[0].nameStudentEatSevenDay.length:0
  }
}
export const TotalArr =(arr)=>{
  //console.log(arr)
    let TotalStudent      = arr.map(v=>v.result.NumStudent)
    let TotalStudentEat7  = arr.map(v=>v.result.NumStudentEat7)
    let TotalBoarding     = arr.map(v=>v.result.arrSumResult.sumBoarding)
    let TotalBreaf        = arr.map(v=>v.result.arrSumResult.sumBreaf)
    let TotalClean        = arr.map(v=>v.result.arrSumResult.sumClean)
    let TotalDailyTui     = arr.map(v=>v.result.arrSumResult.sumDailyTui)
    let TotalEatPrv       = arr.map(v=>v.result.arrSumResult.sumEatPrv)
    let TotalInFras       = arr.map(v=>v.result.arrSumResult.sumInFras)
    let TotalLearnLg      = arr.map(v=>v.result.arrSumResult.sumLearnLg)
    let TotalNotEat       = arr.map(v=>v.result.arrSumResult.sumNotEat)
    let TotalNumEat       = arr.map(v=>v.result.arrSumResult.sumNumEat)
    let TotalNumEat7      = arr.map(v=>v.result.arrSumResult.sumNumEat7)
    let TotalNumEatUse    = arr.map(v=>v.result.arrSumResult.sumNumEatUse)
    let TotalPriceMeal    = arr.map(v=>v.result.arrSumResult.sumPriceMeal)
    let TotalSeven        = arr.map(v=>v.result.arrSumResult.sumSeven)
    //let TotalCheckEatSeven= arr.map(v=>v.result.arrSumResult.sumCheckSevenEat)    
    let TotalSum          = arr.map(v=>v.result.arrSumResult.sumSum)
    let TotalSummer       = arr.map(v=>v.result.arrSumResult.sumSummer)    

    let Item = {
      TotalStudent     : TotalStudent.reduce((x,y)=>x+y,0),
      TotalStudentEat7 : TotalStudentEat7.reduce((x,y)=>x+y,0),
      TotalBoarding    : TotalBoarding.reduce((x,y)=>x+y,0),
      TotalBreaf       : TotalBreaf.reduce((x,y)=>x+y,0),
      TotalClean       : TotalClean.reduce((x,y)=>x+y,0),
      TotalDailyTui    : TotalDailyTui.reduce((x,y)=>x+y,0),
      TotalEatPrv      : TotalEatPrv.reduce((x,y)=>x+y,0),
      TotalInFras      : TotalInFras.reduce((x,y)=>x+y,0),
      TotalLearnLg     : TotalLearnLg.reduce((x,y)=>x+y,0),
      TotalNotEat      : TotalNotEat.reduce((x,y)=>x+y,0),
      TotalNumEat      : TotalNumEat.reduce((x,y)=>x+y,0),
      TotalNumEat7     : TotalNumEat7.reduce((x,y)=>x+y,0),
      TotalNumEatUse   : TotalNumEatUse.reduce((x,y)=>x+y,0),
      TotalPriceMeal   : TotalPriceMeal.reduce((x,y)=>x+y,0),
      TotalSeven       : TotalSeven.reduce((x,y)=>x+y,0),
      //TotalCheckEatSeven:TotalCheckEatSeven.reduce((x,y)=>x+y,0),
      TotalSum         : TotalSum.reduce((x,y)=>x+y,0),
      TotalSummer      : TotalSummer.reduce((x,y)=>x+y,0)
    }
   /// console.log(TotalSeven)
   // console.log(TotalCheckEatSeven)
    return Item
}
// const FindTotalEat7 =(Arr_7_Current,numStudent)=>{
//     let result = Arr_7_Current.map(v=>{
//       return numStudent - v.nameStudentNotEat.length
//     })
//    return result.reduce((x,y)=>x+y,0)
// }
const findSumPrvInArrStudent = (ArrCurrentStudent,ArrPrvStudent)=>{
      return ArrCurrentStudent.map(v=>{
        let index = ArrPrvStudent.findIndex(i=>i._id===v._id)
        if(index>=0){
          return{
            ...v,
            SumPrv:ArrPrvStudent[index].sum
          }
        }else{
          return {...v}
        }
    })
}
const FindArrStudentSeven = (ArrCurrentStudent,ItemRegis)=>{
    let ArrRegisSeven = ItemRegis.length!==0?ItemRegis[0].nameStudentEatSevenDay:[]
    return ArrCurrentStudent.map(v=>{
      let index = ArrRegisSeven.findIndex(i=>i.idStudent===v._id)
      if(index>=0){
        return {
          ...v,
          SevenEat:true
        }        
      }else{
        return {
          ...v,
          SevenEat:false
        }
      }
    })
}
const findArrStudent =(ArrCurrentStudent,Prices)=>{    
   return ArrCurrentStudent.map(v=>{
    let ValueCheck   = v.SevenEat
    let _SumPrv      = v.SumPrv===undefined?0:v.SumPrv
    let SumNumEatUse = ValueCheck===true?(Prices.NumEat+Prices.NumEat7)-_SumPrv:Prices.NumEat-_SumPrv
    //console.log(v.SumPrv)
    let SumPriceMeal = Prices.PriceMeal*SumNumEatUse
    let CheckSevenEat = ValueCheck===true?(Prices.Seven*Prices.NumEat7):0
    //console.log(CheckSevenEat)
    let ValueSum = (SumPriceMeal+Prices.Clean+Prices.InFras+Prices.DailyTui+
      Prices.Summer+Prices.Breaf+Prices.LeanLg+Prices.Boarding+CheckSevenEat)    
    let Item = {
      name      : v.fullName,
      NumEat    : Prices.NumEat, //so ngay an trong thang
      NumEat7   : ValueCheck===true?Prices.NumEat7:0, //so buoi an thu 7
      EatPrv    : _SumPrv, //so phieu con du thang truoc
      NumEatUse : SumNumEatUse, //so phieu phai mua thang nay
      PriceMeal : SumPriceMeal,
      DailyTui  : Prices.DailyTui, //ok  hoc phi
      Summer    : Prices.Summer, //ok hoc he
      Breaf     : Prices.Breaf, //ok phuc vu an sang/tien an sang
      Boarding  : Prices.Boarding,//ok
      Clean     : Prices.Clean,//ok
      InFras    : Prices.InFras,//ok
      Seven     : Prices.Seven,//gia bang don gia ngay nhan so buoi hoc
      LeanLg    : Prices.LeanLg,    //ok  
      Sum       : ValueSum,
      NotEat    : v.sum, //so ngay nghi 1 hoc sinh thang hien tai
      PriceNum  : Prices.PriceMeal,
      AmountPay : (ValueSum-(v.sum*Prices.PriceMeal)),
      CheckSevenEat, 
      ValueCheck
    }  
    return Item
  })  
}

const HandleValueSum=()=>{

}
const FindStudentNotEatCurrent=(ArrDay,CurrentStudent,id)=>{  
          let ArrClass = ArrDay.filter(v=>v.idClass===id)
          let ClassFind = ArrClass.map(v=>{
                let arr ={      
              NotEat:v.nameStudentNotEat
            }
            return arr
          })
        let newArrIdStudent = ClassFind.map(v=>{    
          return v.NotEat.map(i=>{ 
            let item={id:i.idStudent}
            return item
          })  
        })

        let ARR =   _.flatten(newArrIdStudent)
        let ARR2 = ARR.reduce(function(v,i){
        v[i.id] = (v[i.id]||0)+1    
        return v
        },{})  
        let ARR3 = Object.entries(ARR2)  
        ARR3 = ARR3.map(([key,value])=>{      //xac dinh so hoc sinh nghi hoc
        let item={
          id:key,
          sum:value
        }  
        return item
        })  
        // tinh ra ket qua trong lop co bao nhieu hs nghi hoc
        let ARR4 = StudentNotEat(ARR3,CurrentStudent)        
       return ARR4.length>0?ARR4:[]//dieu kien khu loi bao rong    
     
}
export const SumPrices = (arr)=>{  
  let result ={
       sumSummer       : 0, //phi hoc he       
       sumNumEat       : 0, //so phieu an
       sumNumEat7      : 0, //so phieu thu 7
       sumEatPrv       : 0, //so phieu thua thang truoc
       sumNumEatUse    : 0, //so phieu phai an thang nay
       sumDailyTui     : 0, //hoc phi
       sumPriceMeal    : 0, //tien an hang nagy
       sumBoarding     : 0, //ban tru
       sumClean        : 0, //ve sinh
       sumInFras       : 0, //csvc
       sumBoarding     : 0, //ban tru
       sumBreaf        : 0, //an sang
       sumSeven        : 0, //pv thu 7
       sumLearnLg      : 0, //hoc tieng anh
       sumCheckSevenEat:0,

       sumSum          : 0, //2
       sumNotEat       : 0, //khong an
      // sumPriceNum     : 0, //
       //sumAmountPay    : 0  //1 so tien thua tra lai
  }      
  arr.map(v=>{
   result.sumSummer        += v.Summer
   result.sumPriceMeal     += v.PriceMeal
   result.sumNumEat        += v.NumEat
   result.sumNumEat7       += v.NumEat7
   result.sumEatPrv        += v.EatPrv
   result.sumNumEatUse     += v.NumEatUse//so phieu an phai mau thang hien tai
   result.sumDailyTui      += v.DailyTui
   result.sumBreaf         += v.Breaf
   result.sumSeven         += v.Seven
   result.sumClean         += v.Clean
   result.sumBoarding      += v.Boarding
   result.sumInFras        += v.InFras
   result.sumLearnLg       += v.LeanLg
   result.sumCheckSevenEat +=v.CheckSevenEat

   result.sumSum          += v.Sum //so tien phai thu
   result.sumNotEat       += v.NotEat
   //result.sumPriceNum     += (v.PriceNum*v.NotEat)
   //result.sumAmountPay    += v.AmountPay //tien phai tra
  })
  //console.log(result)
  return result
}


export const StudentListLeaveTemporaty =(arr,arrItem)=>{ 
  let newList = _.first(arrItem)
   //let handleNewList = HandleNewList(arr)
  if(newList===undefined||newList.students.length===0)return arr
  return handleStudentListLeave(arr,arr.students)
}

const handleStudentListLeave=(arr,arrList)=>{
    if(arr===undefined)return []
    let result= arr.map(v=>{
      let index = arrList.findIndex(vv=>vv.fullName===v.name&&vv.active===true)
      let Index_1 = arrList.findIndex(vv=>vv.fullName===v.name&&vv.active===false)
      if(Index_1!==-1){
        return{
          ...v,
          EatPrv:arrList[Index_1].numFood
        }
      }
      if(index!==-1){
        let item={
            AmountPay:0,
            Boarding:0,
            Breaf:0,
            CheckSevenEat:0,
            Clean:0,
            DailyTui:0,
            EatPrv:arrList[Index_1].numFood,
            InFras:0,
            LeanLg:0,
            NotEat:0,
            NumEat:0,
            NumEat7:0,
            NumEatUse:0,
            PriceMeal:0,
            PriceNum:0,
            Seven:0,
            Sum:0,
            Summer:0,
            ValueCheck:false,
            name:v.name,
        }
        return item       
      }
        return {
          ...v
        }
      
    })
    return result
}

const StudentNotEat = (arrNotEat,arrStudent)=>{    
  let SUM={sum:0}
    let Student = arrStudent.length>0?arrStudent:[]
    return Student.map(item=>{
      let index = arrNotEat.findIndex(value=>value.id===item._id)
      if(index>=0){
        return{
          ...item,
          ...arrNotEat[index]
        }
      }else{
        return {...item,
                ...SUM
        }
      }
    })
}
//xu ly thong tin 1 lop hs trong thang

//xu ly thong tin truong trong thang
export const PaymentListTotal=(arr,item,ListClass,ListStudent,Prices,ListRegis,ListTranfer)=>{
   return HandleResultInClass(ListClass,ListStudent,arr,item,Prices,ListRegis,ListTranfer)
}
//xu ly thong tin truong trong thang
const HandleResultInClass =(arrClass,ListStudent,arr,item,Prices,ListRegis,ListTranfer)=>{  
  return arrClass.map(v=>{
     let filterListTransferByClass = FilterItemTransferByClassByMonthPaymentListTotal(ListTranfer,v.idBlockClass)
     let Item = {
       result:PaymentList(arr,item,v._id,ListStudent,Prices,ListRegis,filterListTransferByClass),
       nameClass:v.nameClass
     } 
     return Item
   })    
}

//student leave temporaty Payment list total class

export const StudentLeaveTempPaymentListTotal =(arrPayment,arrList,arrClass)=>{ 
    let newPayment = HandleNewPayment(arrPayment,arrList,arrClass)
    let joinArrListArrClass = JoinList_Class(arrList,arrClass)
    let JoinArrPayment_ArrLisClass = JoinPayment_ListClass(newPayment,joinArrListArrClass)
    let result = PaymentListClass(JoinArrPayment_ArrLisClass)    
    return result
}

const HandleNewPayment =(arrPayment,arrList,arrClass)=>{   
    let newArrList = arrList.map(v=>{
      let index = arrClass.findIndex(vv=>vv._id===v.idClass)
      if(index!==-1){
        return {
          ...v,
          nameClass:arrClass[index].nameClass
        }
      }else{
        return{
          ...v
        }
      }
    })
    return  arrPayment.map(v=>{
      let index = newArrList.findIndex(vv=>vv.nameClass===v.nameClass)
      if(index!==-1){
        return{
          ...v,
          result:{
            ...v.result,
            NumStudent:v.result.NumStudent - HandleStuTmp(newArrList[index])
          }
        }
      }return {...v}
    })
} 

const HandleStuTmp=(item)=>{
    return (item.students.filter(v=>v.active===true)).length
}

const JoinList_Class =(arrList,arrClass)=>{
    let result=  arrList.map(v=>{
      let index = arrClass.findIndex(vv=>vv._id===v.idClass)
      if(index!==-1){
        return {
          ...v,
          nameClass:arrClass[index].nameClass
        }
      }else{
        return null        
      }
    })
    return result.filter(v=>v!==null)
}

const JoinPayment_ListClass =(payment,listclass)=>{
  let result = payment.map(v=>{
    let index = listclass.findIndex(vv=>vv.nameClass.toLowerCase()===v.nameClass.toLowerCase())
    if(index!==-1){
      return {
        ...v,
        itemClass:listclass[index]
      }
    }else{
      return null
    }
  })
  return result.filter(v=>v!==null)
}

const PaymentListClass=(arrPayment)=>{
    return arrPayment.map(v=>{
      if(v.itemClass.students.length===0)return v
        let newListStudent = handleStudentListLeave(v.result.arrStudent,v.itemClass.students)        
        return{
          ...v,
          result:{
            ...v.result,
            arrStudent:newListStudent,
            arrSumResult:SumPrices(newListStudent)
          }          
        }
    })
}


export const ListCheckDateFoodToAddNewStudent =(ListDateFood,idClass,idYearSchool)=>{    
  let v =  ListDateFood.filter(v=> v.idClass===idClass &&
                                  v.idYearSchool===idYearSchool
   )
   return v
}

export const ListCheckDateFoodToViewPaymentList =(ListDateFood,DateInput
    //,idYearSchool  
  )=>{       
  return ListDateFood.some(v=>showMMYY(v.dateNow)===showMMYY(DateInput))   
}

//material select price meal date
export const ListPriceMaterialByDate = (arrPrices,dateInput)=>{
   return arrPrices.filter(v=>showMMYY(v.dateMeal)===showMMYY(dateInput))
}
//export const NumberDate

export const HandleSumByDateTypeMaterial=(arr)=>{
  let timeDD = arr.map(v=>showDD(v.dateMeal))
  let newTimeDD = timeDD.filter((item,index)=>timeDD.indexOf(item)===index)
  let result=newTimeDD.map(v=>arr.filter(vv=>showDD(vv.dateMeal)===v))
  let findArr= newTimeDD.map(v=>{
    let itemResult = arr.filter(vv=>showDD(vv.dateMeal)===v)   
    let sumByDate  = Math.ceil(sumTotal(itemResult))
    let item = {
      date:v,
      sum:true,
      sumByDate
    }
    return item
  })
 let newArr = result.map(v=>{
    let Arr = v
    findArr.map(vv=>{
      let item ={}
      let index = v.findIndex(value=>showDD(value.dateMeal)===vv.date)
      if(index>=0){
         item={
           nameTypeMaterial:"tong",
           unit:"tong",
          price:vv.sumByDate,
          quantity:1,
          sum:true,
          dateMeal:v[index].dateMeal
        }
          Arr.push(item)   
      }      
   })
   return Arr
 })
 return _.flatten(newArr)
}

export const ResultListMaterialByDate = (arrPriceByDate,arrTypeMaterial,arrMaterial)=>{   
  let arrMaterialNew     = NewArrMaterial(arrMaterial)
  let arrTypeMaterialNew = NewArrTypeMaterial(arrTypeMaterial)
  let arr1               = handleFindIndexTypeMaterial(arrPriceByDate,arrTypeMaterialNew)  
  let arrResult          = handleFindIndexCateMaterial(arr1,arrMaterialNew)    
  let CateMaterial       = arrResult.map(v=>v.nameMaterial)  
  let NewCate            = CateMaterial.filter((item,index)=>CateMaterial.indexOf(item)===index)
  let result={
    cate:NewCate.sort(),
    arrPrice:arrResult,
    typeMaterial:arr1
  }      
  return result    
}

export const handleFindIndexTypeMaterial = (arrPriceByDate,arrTypeMaterial)=>{
  return arrPriceByDate.map(item=>{
    let index = arrTypeMaterial.findIndex(value=>value._id===item.idTypeMaterial)
    if(index>=0){
      return{
        ...item,
        ...arrTypeMaterial[index]
        }  
       }else{
       return {...item}
    }
  })
}

export const handleFindIndexCateMaterial = (arr1,arrMaterial)=>{
  return arr1.map(item=>{
    let index = arrMaterial.findIndex(value=>value._id===item.idMaterial)
    if(index>=0){
      return{
        ...item,
        ...arrMaterial[index]
      }
    }else{
      return{...item}
    }
  }) 
}

const NewArrMaterial = (arrMaterial)=>{
 return arrMaterial.map(v=>{
    let item={
        nameMaterial:v.name,
        ...v
      }
      return item
  })
}

const NewArrTypeMaterial = (arrTypeMaterial)=>{
    return arrTypeMaterial.map(v=>{
        let item ={
          nameTypeMaterial:v.name,
          ...v
        }
        return item
      }) 
 }

//material select price meal date
//regis seven day
export const FindItemRegisSevenDayMN=(arrListStudent,ListRegis,itemClass,date)=>{ 
      let itemRegis = FindItemRegisSevenDay(ListRegis,itemClass,date)
      if(itemRegis.length!==0){
        let ItemREGIS = _.first(itemRegis).nameStudentEatSevenDay
        let findArr   = ItemREGIS.map(item=>{
        let index     = arrListStudent.findIndex(value=>value._id===item.idStudent)
          if(index>=0){
            return{
              ...arrListStudent[index],        
            }      
          }
          else{
            return false
          }
        }) 
        return findArr.filter(v=>v!==false)  
      }
      return []      
}
export const FindExitsListRegisSevenDay=(arrRegis,itemClass,date)=>{  
  //console.log(arrRegis)
  //console.log(itemClass)
  if(itemClass===undefined){
    return false
  }
  if(itemClass!==undefined){
    return arrRegis.some(v=>v.userId              === itemClass.userId&&
                            v.idClass            === itemClass.idClass&&
                            v.idBlockClass       === itemClass.idBlockClass&&
                            v.idYearSchool       === itemClass.idYearSchool&&
                            showMMYY(v.dateMonth)===showMMYY(date)
    ) 
  }
}
export const FindItemRegisSevenDay=(arrRegis,itemClass,date)=>{   
  //console.log(itemClass)
  if(itemClass===undefined){
    return []
  }
  if(itemClass!==undefined){
     let arr =  arrRegis.filter(v=>v.userId              === itemClass.userId&&
                             v.idClass            === itemClass.idClass&&
                             v.idBlockClass       === itemClass.idBlockClass&&
                             v.idYearSchool       === itemClass.idYearSchool&&
                             showMMYY(v.dateMonth)===showMMYY(date)
    )     
   return  arr
  } 
 }

//regis seven day
export const FindItemCheckDayFood=(ListCheckDateFood,ItemClass,CurrentDate)=>{
      return ListCheckDateFood.filter(v=>v.userId === ItemClass.userId &&
                                        v.idClass === ItemClass.idClass &&
                                        v.idBlockClass ===ItemClass.idBlockClass &&
                                        v.idYearSchool === ItemClass.idYearSchool &&
                                        showYYMMDD(v.dateNow) === showYYMMDD(CurrentDate)
        )
}
//tong hop bao an
 export const ClassCountEatMonth=(arrClass,arrCheckFood,date,arrStudent,arrListSeven,arrLeaveTmp)=>{    
    let Num                              = numberDate(showMM(date),showYYYY(date))
    let arrListRegisSeven                = HandleAllArrListRegisSeven(arrListSeven)
    let FilterArrCheckFoodByCurrentMonth = arrCheckFood.filter(v=>showMMYY(v.dateNow)===showMMYY(date))
    let sumTotal = handleSumTotal(arrStudent,Num,arrCheckFood,date,arrListRegisSeven,arrLeaveTmp)
    let result   = handleFindArrClass(arrClass,FilterArrCheckFoodByCurrentMonth,arrStudent,arrListRegisSeven,Num,date,arrLeaveTmp)  
    let Result={
      result,
      sumTotal
    }
    //console.log(result)
    //console.log(sumTotal)
    return Result
 }


 const handleSumTotal=(arrStudent,Num,arrCheckFood,date,arrListSeven,arrLeaveTmp)=>{   
    let arrSum         =  ArrNumDate(Num).map((i)=>{
        let DD         = handleMM(i)
        let MM         = showMM(date)
        let YYYY       = showYYYY(date)
        let DDMMYY     = `${YYYY}/${MM}/${DD}`
        let CheckSeven = showDateName(DDMMYY)
        let ItemDate   = showYYMMDD(DDMMYY)
        //let ItemDateThuBay = 'thứ bảy'
        let FilterBySumClassNotSeven = arrCheckFood.filter(v=>showYYMMDD(v.dateNow)===ItemDate&&showDateName(v.dateNow)!==ItemDateThuBay)
        console.log(FilterBySumClassNotSeven)
        let FilterBySumClassSeven    = arrCheckFood.filter(v=>showDateName(v.dateNow)===ItemDateThuBay&&showYYMMDD(v.dateNow)===ItemDate)
        
        if(CheckSeven===ItemDateThuBay&&FilterBySumClassSeven.length!==0){            
          let findSum = HandleFindSumByDate(FilterBySumClassSeven,arrListSeven)
          return findSum.reduce((x,y)=>x+y,0)
        }
        if(showDateName(DDMMYY)!==ItemDateThuBay&&FilterBySumClassNotSeven.length!==0){             
          let findSum = HandleFindSumByDateNotSevenDay(FilterBySumClassNotSeven,arrStudent,arrLeaveTmp)
          console.log(findSum)
          return findSum.reduce((x,y)=>x+y,0)
        }
        return 0
      })
      //console.log(arrSum)
      let lastSum = arrSum.reduce((x,y)=>x+y,0)
     // console.log(lastSum)
      arrSum.push(lastSum)
      return arrSum
 }

  const HandleFindSumByDate =(arrSumClass,ListStudent)=>{
      return arrSumClass.map(v=>{
      let NumStudentByClass = ListStudent.filter(i=>i.idClass===v.idClass)
      return  NumStudentByClass.length - v.nameStudentNotEat.length
      })
  }
  const HandleFindSumByDateNotSevenDay =(arrSumClass,ListStudent,arrLeaveTmp)=>{
    if(arrLeaveTmp===undefined){
        return arrSumClass.map(v=>{
        let NumStudentByClass = ListStudent.filter(i=>i.idClass===v.idClass)
        return  NumStudentByClass.length - v.nameStudentNotEat.length
        })
    }
    return arrSumClass.map(v=>{
      let NumStudentByClass  = ListStudent.filter(i=>i.idClass===v.idClass)
      let filterLeaveTmp     = arrLeaveTmp.filter(vv=>vv.idClass===v.idClass)
      let NumStudentLeaveTmp = (filterLeaveTmp.map(vvv=>vvv.students.filter(j=>j.active===true).length)).reduce((x,y)=>x+y,0)
      return  NumStudentByClass.length - v.nameStudentNotEat.length - NumStudentLeaveTmp
      })    
}

 const ItemDateThuBay = "thứ bảy"
 const handleFindArrClass = (arrClass,FilterArrCheckFoodByCurrentMonth,arrStudent,arrListRegis,Num,date,arrLeaveTmp)=>{
  return arrClass.map(v=>{
    let nameClass             = v.nameClass        
    let FilterStudent         = arrStudent.filter(i=>i.idClass===v._id)
    let FilterStudentSeven    = arrListRegis.filter(i=>i.idClass===v._id)
    let FilterLeaveTmp        = arrLeaveTmp!==undefined?arrLeaveTmp.filter(vv=>vv.idClass===v._id):[]
    let NumStudentLeaveTmp    = FilterLeaveTmp.length!==0?FilterLeaveTmp[0].students.filter(v=>v.active===true).length:0
    let NumStudent            = FilterStudent.length //si so lop
    let NumStudentSeven       = FilterStudentSeven.length
    let ArrNumByDate          = ArrNumDate(Num)
    let FindDateByNumberCheck = handleDateByNumber(ArrNumByDate,FilterArrCheckFoodByCurrentMonth,date,NumStudent,NumStudentSeven,v._id,NumStudentLeaveTmp)
    let result ={
      nameClass,
      FindNumber:FindDateByNumberCheck
    }
    return result        
  })
 }
 const handleDateByNumber = (ArrNumByDate,FilterArrCheckFood,date,NumStudent,NumStudentSeven,idClass,NumStudentLeaveTmp)=>{
       let findArr = ArrNumByDate.map((i)=>{
            let DD       = handleMM(i)
            let MM       = showMM(date)
            let YYYY     = showYYYY(date)
             let DDMMYY  = `${YYYY}/${MM}/${DD}`
            let ItemDate = showYYMMDD(DDMMYY)
            let CheckSeven = showDateName(DDMMYY)
            let filterByItemDateNotSeven = FilterArrCheckFood.filter(v=>showYYMMDD(v.dateNow)===ItemDate&&v.idClass===idClass&&showDateName(v.dateNow)!==ItemDateThuBay) 
            let filterByItemDateSeven    = FilterArrCheckFood.filter(v=>showYYMMDD(v.dateNow)===ItemDate&&v.idClass===idClass&&showDateName(v.dateNow)===ItemDateThuBay) 
              if(CheckSeven===ItemDateThuBay&&filterByItemDateSeven.length!==0){
                return NumStudentSeven - filterByItemDateSeven[0].nameStudentNotEat.length
              }
              if(CheckSeven!==ItemDateThuBay&&filterByItemDateNotSeven.length!==0){              
                          return NumStudent - filterByItemDateNotSeven[0].nameStudentNotEat.length - NumStudentLeaveTmp}   
               return 0                    
      })
       let sum = findArr.reduce((x,y)=>x+y,0)   
       findArr.push(sum)
       return findArr
 }
//tong hop bao an

// //tong hop cham an
 export const ListStudentNotEatMonth=(ItemClass,arrCheckFood,date,arrStudent,ClassTmp)=>{
  let Num = numberDate(showMM(date),showYYYY(date))
  let FilterArrCheckFoodByCurrentMonthByClass = arrCheckFood.filter(v=>showMMYY(v.dateNow)===showMMYY(date)&&ItemClass._id===v.idClass) //ok
  let FilterListStudent                       = arrStudent.filter(v=>v.idClass===ItemClass._id) //ok
  let sumTotalStudentEat                      = handleFindSumStudentEat(FilterListStudent,Num,FilterArrCheckFoodByCurrentMonthByClass,date,ClassTmp)
  let findSumCheckDayFoodInDay                = handleCheckDayFoodInDay(Num,FilterArrCheckFoodByCurrentMonthByClass,date)  
  return {sumTotalStudentEat,findSumCheckDayFoodInDay}
}

 export const CheckDayFoodCoutEatForClassAccout=(date,FilterListStudent,filterArrCheckFood,ClassTmp)=>{
  let Num                         = numberDate(showMM(date),showYYYY(date))
  let sumTotalStudentEat          = handleFindSumStudentEat(FilterListStudent,Num,filterArrCheckFood,date,ClassTmp)
  let findSumCheckDayFoodInDay    = handleCheckDayFoodInDay(Num,filterArrCheckFood,date)  
  return {sumTotalStudentEat,findSumCheckDayFoodInDay}
 }

const handleCheckDayFoodInDay=(Num,arrCheckFood,date)=>{
     let resultSum = ArrNumDate(Num).map((i)=>{
      let DD       = handleMM(i)
      let MM       = showMM(date)
      let YYYY     = showYYYY(date)
      let ItemDate = showYYMMDD(`${YYYY}/${MM}/${DD}`)
      let FilterFindArrCheckFood = arrCheckFood.filter(va=>showYYMMDD(va.dateNow)===ItemDate)        
      if(FilterFindArrCheckFood.length===0||FilterFindArrCheckFood[0].nameStudentNotEat.length===0){
        return 0
      }
     let findItem = FilterFindArrCheckFood[0].nameStudentNotEat.length
     return findItem         
    })
    resultSum.push(resultSum.reduce((x,y)=>x+y,0))
    return resultSum
}

const handleFindSumStudentEat=(FilterListStudent,Num,arrCheckFood,date,ClassTmp)=>{
    return FilterListStudent.map(item=>{
          let name     = item.fullName
          let arrSum   = ArrNumDate(Num).map((i)=>{          
          let DD       = handleMM(i)
          let MM       = showMM(date)
          let YYYY     = showYYYY(date)
          let ItemDate = showYYMMDD(`${YYYY}/${MM}/${DD}`)
          if(ClassTmp.length!==0){
            let CheckStudentExist = ClassTmp[0].students.some(v=>v.idStudent===item._id&&v.active===true)
            if(CheckStudentExist){
              return 'TMP'//nghi tam thoi
            }
         }
          let FilterFindArrCheckFood         = arrCheckFood.filter(va=>showYYMMDD(va.dateNow)===ItemDate)  
          if(FilterFindArrCheckFood.length===0) return 0
          let findItemStudentNotEat = FilterFindArrCheckFood[0].nameStudentNotEat.filter(value=>value.idStudent===item._id)
          if(FilterFindArrCheckFood.length!==0&&findItemStudentNotEat.length===0) return false
          if(FilterFindArrCheckFood.length!==0&&findItemStudentNotEat.length!==0) return true
       })         
        arrSum.push(arrSum.reduce((x,y)=>{
        if(x!=='TMP'&&y!=='TMP'){
          return x+y
         }}    
        ))
          let result={
            name,
            arrSum
          }
          return result       
        })
}
//tong hop cham an

//regis seven day dau thang va bao si so
export const RegisSevenDayBeginMonth =(FilterListRegisSevenDay,arrStudentInLast,ShowDateMonth)=>{
    let ListRegisSevenByClass = FilterListRegisSevenDay.filter(v=>
        v.userId===arrStudentInLast[0].userId&&
        v.idClass===arrStudentInLast[0].idClass&&
        v.idBlockClass===arrStudentInLast[0].idBlockClass&&
        v.idYearSchool===arrStudentInLast[0].idYearSchool&&
        showMMYY(v.dateMonth)===handlePrvMonth(ShowDateMonth)
    )
    if(ListRegisSevenByClass.length!==1){    
    return false
    }
    if(ListRegisSevenByClass.length===1){
    let ListStudentClass      = arrStudentInLast[0].listStudentsNow
    let ListStudentClassSeven = ListRegisSevenByClass[0].nameStudentEatSevenDay
    let resultListStudent     = ListStudentClassSeven.map(v=>{
    let index = ListStudentClass.findIndex(i=>i.idStudent===v.idStudent)
    if(index>=0){
    return {
    ...v
    }
    }else{
    return false
    }
    })
    let result = resultListStudent.filter(v=>v!==false)   
      let itemRegis = {
      userId:arrStudentInLast[0].userId,
      idClass:arrStudentInLast[0].idClass,
      idBlockClass:arrStudentInLast[0].idBlockClass,
      idYearSchool:arrStudentInLast[0].idYearSchool,
      dateMonth:ShowDateMonth,
      nameStudentEatSevenDay:result
      }   
      return itemRegis
    }
}
//regis seven day dau thang va bao si so
