import {ADD_BLOCK,LIST_TABLE_BLOCK,ADD_BLOCK_TO_STORE,
  EDIT_BLOCK,DISPATCH_ROW_BLOCK_TO_STORE,
  DISPATCH_LIST_CLASS_TO_STORE,
  DISPATCH_ADD_CLASS_TO_STORE,
  EDIT_CLASS,
  DISPATCH_ROW_CLASS_STUDENT,DISPATCH_LIST_ALL_STUDENT_TO_STORE,
  DISPATCH_ADD_STUDENT_TO_STORE,CLOSE_FORM_STUDENT,OPEN_FORM_IMPORT_EXCEL,
  CLOSE_FORM_IMPORT_EXCEL, UPDATE_ARR_AFTER_UPLOAD_EXCEL,
  OPEN_FORM_ADD_YEAR_SCHOOL,CLOSE_FORM_ADD_YEAR_SCHOOL,
  OPEN_ADD_YEAR_SCHOOL,CLOSE_ADD_YEAR_SCHOOL,DISPATCH_ADD_YEAR_SCHOOL_TO_STORE,
  DISPATCH_LIST_YEAR_SCHOOL_TO_STORE,
  ADD_YEAR_SCHOOL_SUCCESS,
  OPEN_FORM_SELECT,
  CLOSE_FORM_SELECT,
  DISPATCH_CREATE_YEAR_SCHOOL,OPEN_EDIT_YEAR_SCHOOL,
  CLOSE_EDIT_YEAR_SCHOOL,EDIT_YEAR_SCHOOL,OPEN_EDIT_ROW_STUDENT_YEAR_SCHOOL,CLOSE_EDIT_ROW_STUDENT_YEAR_SCHOOL,
  EDIT_STUDENT_YEAR_SCHOOL,DELETE_STUDENT_YEAR_SCHOOL,
  OPEN_FORM_ALERT,CLOSE_FORM_ALERT, OPEN_FORM_ALERT_EDIT, CLOSE_FORM_ALERT_EDIT,
  OPEN_FORM_ALERT_ADD,CLOSE_FORM_ALERT_ADD,OPEN_CHECK_DAY_FOOD,CLOSE_CHECK_DAY_FOOD,
  OPEN_FORM_ALERT_ADD_DATE_MEAL,CLOSE_FORM_ALERT_ADD_DATE_MEAL,OPEN_FORM_ADD_DATE_MEAL,CLOSE_FORM_ADD_DATE_MEAL,
  ALERT_OPEN_ADD_EMPTY_CHECK_DAY_FOOD,ALERT_CLOSE_ADD_EMPTY_CHECK_DAY_FOOD,
  DUPLICATED_CLOSE_ALERT_EMPTY_CHECK_DAY_FOOD,DUPLICATED_OPEN_ALERT_EMPTY_CHECK_DAY_FOOD,
  DISPATCH_LIST_CHECK_DATE_FOOD,DISPATCH_ADD_CHECK_DATE_FOOD,VIEW_OPEN_CHECK_DAY_FOOD,VIEW_CLOSE_CHECK_DAY_FOOD
  ,DATE_NOW,DISPATCH_DATE_CHECKFOOD,DISPATCH_NUMBER_STUDENT_NOT_EAT,DISPATCH_ITEM_STUDENT_DATE_INPUT,
  UPDATE_CHECK_DATE_FOOD_SUCCESS,OPEN_CHECK_DAY_FOOD_AGAIN,CLOSE_CHECK_DAY_FOOD_AGAIN,UPDATE_ITEM_STUDENT_CHECK_DATE_FOOD, SEND_IDS_TO_DB_ADD_EMPTY_NOT_EAT,
  DISPATCH_ARRAY_STUDENT_NOT_EAT,REGIS_SEVEN_DAY_TRUE,  
   REGIS_SEVEN_DAY_FALSE,
   OPEN_ALERT_EMPTY_SELECT_REGIS_SEVEN_DAY,CLOSE_ALERT_EMPTY_SELECT_REGIS_SEVEN_DAY,
   DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY_TO_STORE,DISPATCH_LIST_REGIS_SEVEN_TO_STORE,
   OPEN_UPDATE_REGIS_SEVEN_DAY,CLOSE_UPDATE_REGIS_SEVEN_DAY,DISPATCH_ARR_TO_UPDATE_REGIS_SEVEN_DAY,
   DISPATCH_UPDATE_REGIS_SEVEN_DAY,UPDATE_REGIS_SEVEN_DAY_SUCCESS, DELETE_ITEM_REGIS_SEVEN_DAY,
   DELETE_ITEM_CHECK_DATE_FOOD,
   OPEN_ALERT_DELETE_REGIS_SEVEN_DAY,CLOSE_ALERT_DELETE_REGIS_SEVEN_DAY,
   OPEN_ALERT_DELETE_CHECK_DATE_FOOD,CLOSE_ALERT_DELETE_CHECK_DATE_FOOD,DISPATCH_ADD_LIST_STUDENT_NOW_TO_STORE,
   DISPATCH_ALL_LIST_STUDENT_NOW,DISPATCH_LIST_STUDENT_CLASS_BY_YEAR_SCHOOL_DB_LIST_STUENT_NOW_LAST,
   DISPATCH_LIST_STUDENT_IN_CLASS_IN_LAST_MONTH_TO_STORE,DISPATCH_ADD_STUDENT_IN_CLASS_TO_STORE,OPEN_FORM_TO_ADD_NEW_STUDENT_NEW_MONTH,CLOSE_FORM_TO_ADD_NEW_STUDENT_NEW_MONTH,SET_VALUE_UPDATE_OR_ADD_LIST_STUDENT_NOW,
   DELETE_ITEM_STUDENT_NOW,ALERT_ADD_STUDENT_NOW_CLOSE_FORM,ALERT_ADD_STUDENT_NOW_OPEN_FORM,
   ALERT_CLOSE_ADD_STUDENT_NOW_AGAIN,ALERT_OPEN_ADD_STUDENT_NOW_AGAIN,
   OPEN_LIST_VIEW_REGIS_SEVEN_DAY,CLOSE_LIST_VIEW_REGIS_SEVEN_DAY,
   OPEN_LIST_VIEW_CHECK_DAY_FOOD,CLOSE_LIST_VIEW_CHECK_DAY_FOOD,DELETE_ITEM_STUDENT_NOW_SUCCESS,
   OPEN_UPDATE_LIST_STUDENT,CLOSE_UPDATE_LIST_STUDENT,GET_LIST_STUDENT_BY_CLASS_YEAR_SCHOOL, UPDATE_LIST_STUDENT,
   UPDATE_ALERT_SUCCESS_LOGOUT,ADD_STUDENT_AFTER, DELETE_STUDENT_NOW,DISPATCH_STUDENT_LEAVE_TEMPORATY_TO_STORE,
   ADD_STUDENT_LEAVE_TEMPORATY_TO_STORE,
   //SELECT_STUDENT_LEAVE_TEMPORATY,
   UPDATE_STUDENT_LEAVE_TEMPORATY,
   UPDATE_STUDENT_LEAVE_TEMPORATY_AGAIN,
   GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW_TO_STORE,
   DISPATCH_ADD_MANY_CHECK_DATE_FOOD,DELETE_STUDENT_YEAR_SCHOOL_IN_MANAGER_STUDENT, DELETE_CLASS,
   DELETE_ITEM_STUDENT_IN_STUDENT_NOW,DELETE_BLOCK_CLASS
} from '../../../contant/Gov/student/dispatch'
import {ADD_BLOCK_SUCCESS_ALERT,ALERT_CREATE_YEAR_SCHOOL,ALERT_UPDATE_CHECK_DATE_FOOD} from '../../../contant/Gov/student/alert'
import {changeUpperCase,findIndexWhenUpdateById,
       getArrStudentClass,getArrByIdBlockClass,getListStudentClassByYearSchool,DeleteItemById,updateArrById} from '../../../helper/fnAllways'
import {sortArrAsc}from '../../../helper/tableList/tableList'
import {HandleUpdateArrCheckDayFoods,HandleNewArrListStudentNow,HandleAllArrListStudentNow} from '../../../helper/countEat'
import {toastSuccess} from '../../../helper/toastHelper'
import {FindItemRegisSevenDay, 
  //showDDMMYY, 
  showMMYY} from '../../../helper/dateTime/handleDMY'
//import DateInput from '../../../helper/componentFn/FormSelectDate/DateInput'
import {UpdateListStudentNow,UpdateListStudentClassByYearSchool} from './fn'
import { DISPATCH_ADD_DELETE_ITEM_STUDENT_INFO } from '../../../contant/Gov/softFood'
//import { UpdateStudentLeaveTemporatyAgain } from '../../../actions/Gov/student/student'

let initialState ={
    List:[],//danh sach khoi
    row:'',
    ListClass:[],// danh sach lop
    valueRowBlock:'',
    RowClassStudent:'', //thong tin cua 1 lop
    OpenFormStudent:false,//dong mo fom danh sach hoc sinh
    OpenFormImport:false,//dong mo form import
    ItemStudent:"",
    ArrStudent:[],
    ListAllStudent:[],//danh sach tat ca hoc sinh 
    ListStudentClass:[], //danh sach hoc sinh cua 1 lop
    ListStudentClassByYearSchool:[],//danh sach hoc sinh 1 lop theo nam hoc
    ListStudentClassByYearSchoolNow:[],
    ListAllStudentNow:[],
    ListOriginAllStudentNow:[],
    AlertAddStudentNow:false,
    AlertAddStudentNowAgain:false,
    OpenListViewRegisSevenDay:false,
    OpenListViewCheckDateFood:false,
    ValueToUpdateOrAdd:0,
    RowBlock:'', //xac dinh khoi nao de chuan bi xem danh sach cua cac lop theo khoi  
    ListClassBlock:[], //danh sach hoc sinh theo khoi
    OpenFromYearSchool:false,//dong mo form YearSchool alert
    OpenAddYearSchool:false, //dong mo from add Year school
    ListYearSchool:[],//danh sach cac nam hoc,
    ItemYearSchool:'',//thong tin 1 nam hoc sau khi them
    OpenFormSelect:false, //dong mo form select
    NameYearSchool :'',
    ConditionOpenFormSelect:0,//neu khac 0 thi formselect khong bat
    OpenEditYearSchool:false,//form sua nam hoc
    OpenEditRowStudentYearSchool:false, //form sua 1 item nam hoc
    OpenFormAlert:false, // alert form delete
    OpenFormAlertEdit:false,//alert form edit
    OpenFormAlertAdd:false, //alert form add
    CheckDayFood:false,
    ListAllDateMeal:[],
    OpenFormAlertAddDateMeal:false,
    OpenFormAddDateMeal:false,
    DateNow:null,
    DateMeal:null,//xac dinh ngay tinh an la ngay nao
    CheckExistDateMeal:null,
    AlertOpenFormAddCheckDayFood:false, //bat form khi khong co hoc sinh bao vang
    DuplicatedOpenAlertEmptyCheckDayFood:false,
    ListCheckDateFood:[],
    ViewOpenCheckDayFood:false,
    DateInput:'',
    CurrentDate:'',//chua thoi gian hien thi xem
    NumberStudentNotEat:[],
    ItemStudentDateInput:'',//thong tin 1 item student de chuan bi dua vao csdl
    OpenAlertFormCheckDateFood:false,
    IDS:"",
    RegisSevenDay:false,
    OpenAlertRegisSevenDay:false,
    ListRegisSevenDay:[],
    ListRegisSevenDayByClass:[],
    FilterRegisSevenDayByClass:[],
    OpenUpdateRegisSevenDay:false,
    ArrUpdateRegisSevenDay:[],
    OpenAlertDeleteRegisSevenDay:false,
    OpenAlertDeleteCheckDateFood:false,
    OpenFormAddNewStudentNewMonth:false,
    OpenUpdateListStudent:false,
    GetListStudentClassByYearSchool:[],
    StudentLeaveTemporaty:[],
    StudentLeaveTemporatyByClass:[],
    ItemDeleteStudent:null
}

const StudentReducer =(state=initialState,action)=>{
  switch (action.type) {
    case DISPATCH_ADD_DELETE_ITEM_STUDENT_INFO:
      return{
        ...state,
        ItemDeleteStudent:action.payload.item
      }
    case ADD_BLOCK:
      return{
        ...state
      }      

      case ADD_BLOCK_TO_STORE:
          toastSuccess(changeUpperCase(ADD_BLOCK_SUCCESS_ALERT))
        return{
          ...state,
          List:sortArrAsc((state.List).concat(action.payload.item)),
          valueRowBlock:action.payload.item
        }
     case LIST_TABLE_BLOCK:       
       return{
         ...state,
         List:sortArrAsc(action.payload.arr),         
       }
    case EDIT_BLOCK:
      let {item} =action.payload
      let {List} = state
      let arr = sortArrAsc(findIndexWhenUpdateById(List,item))    
        return{
          ...state,
          List:arr
        }      

      case EDIT_CLASS:          
          return{
            ...state,
            ListClassBlock:sortArrAsc(findIndexWhenUpdateById(state.ListClassBlock,action.payload.item)),
            ListClass     :sortArrAsc(findIndexWhenUpdateById(state.ListClass,action.payload.item)),
            List          :sortArrAsc(findIndexWhenUpdateById(state.List,action.payload.item))
          }       
        case DELETE_BLOCK_CLASS:
          return{
            ...state,
            List:DeleteItemById(state.List,action.payload.item)
          }
     case DELETE_CLASS:
      return{
        ...state,
        ListClass:DeleteItemById(state.ListClass,action.payload.item)
      }
      case DISPATCH_ROW_BLOCK_TO_STORE:    
        return{
          ...state,
          RowBlock:action.payload.row,
          ListClassBlock:sortArrAsc(getArrByIdBlockClass(state.ListClass,action.payload.row)),
          ListClass:sortArrAsc(getArrByIdBlockClass(state.ListClass,action.payload.row)),
          List     :sortArrAsc(getArrByIdBlockClass(state.List,action.payload.row))
        }

      case DISPATCH_ADD_CLASS_TO_STORE:
        return{
          ...state,
          ListClassBlock:sortArrAsc((state.ListClassBlock).concat(action.payload.arr)),
          ListClass:sortArrAsc((state.ListClass).concat(action.payload.arr)),
      }

      case DISPATCH_LIST_CLASS_TO_STORE:       
        return{
          ...state,
          ListClass:sortArrAsc(action.payload.arr)
        }

        case DISPATCH_ROW_CLASS_STUDENT:
          let ARR = getArrStudentClass(state.ListAllStudent,action.payload.row)   
          return {
            ...state,
            OpenFormStudent:true,
            RowClassStudent:action.payload.row,             
            ListStudentClassByYearSchool:(getListStudentClassByYearSchool(ARR,state.NameYearSchool)),
            ListStudentClassByYearSchoolNow:(getListStudentClassByYearSchool(ARR,state.NameYearSchool)),
            ListAllStudentNow:state.ListAllStudentNow
          }    

       
        case DISPATCH_LIST_ALL_STUDENT_TO_STORE:         
          return{
            ...state,
            ListAllStudent:action.payload.arr
          }
          case DISPATCH_ADD_STUDENT_TO_STORE:
            return{
              ...state,
              ListStudentClassByYearSchool:((state.ListStudentClassByYearSchool).concat(action.payload.item)),
              ListStudentClassByYearSchoolNow:((state.ListStudentClassByYearSchool).concat(action.payload.item)),
              ListAllStudent:((state.ListAllStudent).concat(action.payload.item)),
              ItemStudent:action.payload.item
            }
            case DISPATCH_ADD_LIST_STUDENT_NOW_TO_STORE:
              let NewArrStudent = HandleNewArrListStudentNow(action.payload.item)
              return{
                ...state,
                ListStudentClassByYearSchool:NewArrStudent,
                ListStudentClassByYearSchoolNow:NewArrStudent,
                ListAllStudent:state.ListAllStudent,
                ListAllStudentNow:state.ListAllStudentNow.concat(NewArrStudent),
                ListOriginAllStudentNow:state.ListOriginAllStudentNow.concat(action.payload.item)
              }
              
              case DISPATCH_LIST_STUDENT_CLASS_BY_YEAR_SCHOOL_DB_LIST_STUENT_NOW_LAST:
                let ArrStudent = HandleNewArrListStudentNow(action.payload.item)
                return{
                  ...state,
                  ListStudentClassByYearSchool:ArrStudent,
                  ListStudentClassByYearSchoolNow:ArrStudent,
                }
                case DELETE_ITEM_STUDENT_NOW:
                  let NewArr = DeleteItemById(state.ListOriginAllStudentNow,action.payload.item[0])
                  return{
                    ...state,
                    ListOriginAllStudentNow:NewArr,     
                    ListAllStudentNow:HandleAllArrListStudentNow(NewArr)     
                  }
                  case DELETE_ITEM_STUDENT_NOW_SUCCESS:
                    toastSuccess('Khôi Phục Thành Công')
                    return{
                      ...state
                    }
              case DISPATCH_ALL_LIST_STUDENT_NOW:               
                return{
                  ...state,
                  ListAllStudentNow:HandleAllArrListStudentNow(action.payload.arr),
                  ListOriginAllStudentNow:action.payload.arr
                }

            case GET_LIST_STUDENT_BY_CLASS_YEAR_SCHOOL:   
              let findStudentNow = state.ListOriginAllStudentNow.filter(v=>showMMYY(v.dateNow)===showMMYY(state.DateInput)&&
                                v.idYearSchool===state.NameYearSchool._id &&
                                v.idClass     ===state.RowClassStudent._id
              )
              return{
                ...state,
                GetListStudentClassByYearSchool:findStudentNow,
              }

              case DELETE_ITEM_STUDENT_IN_STUDENT_NOW:
                toastSuccess('Xoá Thành Công, Bạn nên reload lại Website để cập nhật lại!')
                return{
                  ...state,
                  ListOriginAllStudentNow:updateArrById(state.ListOriginAllStudentNow,action.payload.item)
                }

              case UPDATE_LIST_STUDENT:
                toastSuccess('Cập nhật thành công,Chương trình sẽ tự đăng xuất, bạn vui lòng đăng nhập lại')
                let findItemStudentNow = (action.payload.item).filter(v=>showMMYY(v.dateNow)===showMMYY(state.DateInput)&&
                                v.idYearSchool===state.NameYearSchool._id &&
                                v.idClass     ===state.RowClassStudent._id
                 )
                 let findListStudentNow = UpdateListStudentNow(state.ListOriginAllStudentNow,action.payload.item)
                 let findListStudentByYearSchool = UpdateListStudentClassByYearSchool(state.ListStudentClassByYearSchool,action.payload.newItem)
                return{
                  ...state,
                  ListAllStudentNow:findListStudentNow,
                  GetListStudentClassByYearSchool:findItemStudentNow,
                  ListAllStudentNow:HandleAllArrListStudentNow(findListStudentNow),
                  ListStudentClassByYearSchool:findListStudentByYearSchool
                }

                case UPDATE_ALERT_SUCCESS_LOGOUT:
                  return{
                    ...state
                  }

    
                case DISPATCH_LIST_STUDENT_IN_CLASS_IN_LAST_MONTH_TO_STORE:
                  return{
                    ...state,
                    ListStudentClassByYearSchool:action.payload.arr,
                    ListStudentClassByYearSchoolNow:action.payload.arr,
                  }
                case SET_VALUE_UPDATE_OR_ADD_LIST_STUDENT_NOW:
                  return{
                    ...state,
                    ValueToUpdateOrAdd:action.payload.item
                  }
                
                case DISPATCH_ADD_STUDENT_IN_CLASS_TO_STORE:
                  return{
                    ...state,
                    ListStudentClassByYearSchool:state.ListStudentClassByYearSchool.concat(action.payload.item),
                    ListStudentClassByYearSchoolNow:state.ListStudentClassByYearSchoolNow.concat(action.payload.item),
                  }
                case OPEN_FORM_TO_ADD_NEW_STUDENT_NEW_MONTH:
                  return{
                    ...state,
                    OpenFormAddNewStudentNewMonth:true
                  }
                case CLOSE_FORM_TO_ADD_NEW_STUDENT_NEW_MONTH:
                  return{
                    ...state,
                    OpenFormAddNewStudentNewMonth:false
                  }
                case ALERT_ADD_STUDENT_NOW_OPEN_FORM:
                  return {
                    ...state,
                    AlertAddStudentNow:true
                  }
                case ALERT_ADD_STUDENT_NOW_CLOSE_FORM:
                  return{
                    ...state,
                    AlertAddStudentNow:false
                  }
                case ALERT_OPEN_ADD_STUDENT_NOW_AGAIN:
                  return{
                    ...state,
                    AlertAddStudentNowAgain:true
                  }
                case ALERT_CLOSE_ADD_STUDENT_NOW_AGAIN:
                  return{
                    ...state,
                    AlertAddStudentNowAgain:false
                  }
                case OPEN_LIST_VIEW_REGIS_SEVEN_DAY:
                  return{
                    ...state,
                    OpenListViewRegisSevenDay:true,
                    ValueToUpdateOrAdd:action.payload.item//list view regis seven day                    
                  }
                case CLOSE_LIST_VIEW_REGIS_SEVEN_DAY:
                  return{
                    ...state,
                    OpenListViewRegisSevenDay:false
                  }
                case OPEN_LIST_VIEW_CHECK_DAY_FOOD:
                  return{
                    ...state,
                    OpenListViewCheckDayFood:true,
                    ValueToUpdateOrAdd:action.payload.item
                  }
                case CLOSE_LIST_VIEW_CHECK_DAY_FOOD:
                  return{
                    ...state,
                    OpenListViewCheckDayFood:false
                  }

        //student now
          case CLOSE_FORM_STUDENT:
            return{
              ...state,
              OpenFormStudent:false
            }
          case OPEN_FORM_IMPORT_EXCEL:
            return{
              ...state,
              OpenFormImport:true
            }
            case CLOSE_FORM_IMPORT_EXCEL:
              return{
                ...state,
                OpenFormImport:false,
                OpenFormStudent:true
              }
            case UPDATE_ARR_AFTER_UPLOAD_EXCEL:             
              return{
                ...state,
                ListStudentClassByYearSchool:((state.ListStudentClassByYearSchool).concat(action.payload.arr)),
                ListStudentClassByYearSchoolNow:((state.ListStudentClassByYearSchool).concat(action.payload.arr)),
                ListAllStudent:((state.ListAllStudent).concat(action.payload.arr))
              }            
        //student     
        //yearschool
              case OPEN_FORM_ADD_YEAR_SCHOOL:
                return{
                  ...state,
                  OpenFromYearSchool:true
                }
                case CLOSE_FORM_ADD_YEAR_SCHOOL:
                  return{
                    ...state,
                    OpenFromYearSchool:false
                  }
                case OPEN_ADD_YEAR_SCHOOL:
                  return{
                    ...state,
                    OpenAddYearSchool:true
                  }
                  case ADD_YEAR_SCHOOL_SUCCESS:
                    toastSuccess(changeUpperCase(ALERT_CREATE_YEAR_SCHOOL))
                    return {
                      ...state                      
                    }
                case CLOSE_ADD_YEAR_SCHOOL:
                    return{
                      ...state,
                      OpenAddYearSchool:false
                    }
                case DISPATCH_ADD_YEAR_SCHOOL_TO_STORE:                     
                  return{
                    ...state,
                     ItemYearSchool:action.payload.item,
                    ListYearSchool:sortArrAsc((state.ListYearSchool).concat(action.payload.item))
                  }
                case DISPATCH_LIST_YEAR_SCHOOL_TO_STORE:                  
                  return{
                    ...state,
                    ListYearSchool:sortArrAsc(action.payload.arr)
                  }
                case OPEN_FORM_SELECT:
                  return{
                    ...state,
                    OpenFormSelect:true
                  }
                  case CLOSE_FORM_SELECT:
                  return{
                    ...state,
                    OpenFormSelect:false,
                    ConditionOpenFormSelect:1
                  }
                  case DISPATCH_CREATE_YEAR_SCHOOL:     
                  let StudentClass = getArrStudentClass(state.ListAllStudent,state.RowClassStudent)
                    return{
                      ...state,
                      NameYearSchool:action.payload.valueInput,
                      ListStudentClass:StudentClass,
                      ListStudentClassByYearSchool:sortArrAsc(getListStudentClassByYearSchool(StudentClass,action.payload.valueInput)),
                      ListStudentClassByYearSchoolNow:sortArrAsc(getListStudentClassByYearSchool(StudentClass,action.payload.valueInput))                                 
                    }
                  case OPEN_EDIT_YEAR_SCHOOL:
                    return{
                      ...state,
                      OpenEditYearSchool:true
                    }
                    case CLOSE_EDIT_YEAR_SCHOOL:
                      return{
                        ...state,
                        OpenEditYearSchool:false
                      }
                  case EDIT_YEAR_SCHOOL:
                    return{
                      ...state,
                      ListYearSchool:sortArrAsc(findIndexWhenUpdateById(state.ListYearSchool,action.payload.item))
                    }
                    
                  case OPEN_EDIT_ROW_STUDENT_YEAR_SCHOOL:
                    return{
                      ...state,
                      OpenEditRowStudentYearSchool:true
                    }
                  case CLOSE_EDIT_ROW_STUDENT_YEAR_SCHOOL:
                    return{
                      ...state,
                      OpenEditRowStudentYearSchool:false
                    }
                  case EDIT_STUDENT_YEAR_SCHOOL:
                    return{
                      ...state,
                      ListAllStudent:sortArrAsc(findIndexWhenUpdateById(state.ListAllStudent,action.payload.item)),
                      ListStudentClassByYearSchool:sortArrAsc(findIndexWhenUpdateById(state.ListStudentClassByYearSchool,action.payload.item)),
                      ListStudentClassByYearSchoolNow:sortArrAsc(findIndexWhenUpdateById(state.ListStudentClassByYearSchool,action.payload.item))

                    }
                  case DELETE_STUDENT_YEAR_SCHOOL:
                    return{
                      ...state,
                      ListAllStudent:sortArrAsc(DeleteItemById(state.ListAllStudent,action.payload.item)),
                      ListStudentClassByYearSchool:sortArrAsc(DeleteItemById(state.ListStudentClassByYearSchool,action.payload.item)),
                      ListStudentClassByYearSchoolNow:sortArrAsc(DeleteItemById(state.ListStudentClassByYearSchool,action.payload.item))

                    }
                  case DELETE_STUDENT_YEAR_SCHOOL_IN_MANAGER_STUDENT:
                    return{
                        ...state,
                        ListAllStudent:sortArrAsc(DeleteItemById(state.ListAllStudent,action.payload.item)),
                        
                    }
                    //delete
                  case OPEN_FORM_ALERT:
                    return{
                      ...state,
                      OpenFormAlert:true
                    }
                  case CLOSE_FORM_ALERT:
                    return{
                      ...state,
                      OpenFormAlert:false
                    }
                    //delete
                    //edit
                    case OPEN_FORM_ALERT_EDIT:
                      return{
                        ...state,
                        OpenFormAlertEdit:true
                      }
                    case CLOSE_FORM_ALERT_EDIT:
                      return{
                        ...state,
                        OpenFormAlertEdit:false
                      }
                      //edit
                      //add
                      case OPEN_FORM_ALERT_ADD:
                        return{
                          ...state,
                          OpenFormAlertAdd:true
                        }
                      case CLOSE_FORM_ALERT_ADD:
                        return{
                          ...state,
                          OpenFormAlertAdd:false
                        }
                        //add
                    //yearschool            
                //checkDayfood
                case OPEN_CHECK_DAY_FOOD:
                  return{
                    ...state,
                    CheckDayFood:!state.CheckDayFood
                  }
                  case CLOSE_CHECK_DAY_FOOD:
                  return{
                    ...state,
                    CheckDayFood:false
                  }                
                    case OPEN_FORM_ALERT_ADD_DATE_MEAL:
                      return{
                        ...state,
                        OpenFormAlertAddDateMeal:true
                      }
                      case CLOSE_FORM_ALERT_ADD_DATE_MEAL:
                        return{
                          ...state,
                          OpenFormAlertAddDateMeal:false
                        }
                        case OPEN_FORM_ADD_DATE_MEAL:
                          return{
                            ...state,
                            OpenFormAddDateMeal:true
                          }
                          case CLOSE_FORM_ADD_DATE_MEAL:
                            return{
                              ...state,
                              OpenFormAddDateMeal:false
                            }
                        case ALERT_CLOSE_ADD_EMPTY_CHECK_DAY_FOOD:
                          return{
                            ...state,
                            AlertOpenFormAddCheckDayFood:false
                          }
                          case ALERT_OPEN_ADD_EMPTY_CHECK_DAY_FOOD:
                            return{
                              ...state,
                              AlertOpenFormAddCheckDayFood:true
                            }
                        case DUPLICATED_OPEN_ALERT_EMPTY_CHECK_DAY_FOOD:
                          return{
                            ...state,
                            DuplicatedOpenAlertEmptyCheckDayFood:true
                          }
                        case DUPLICATED_CLOSE_ALERT_EMPTY_CHECK_DAY_FOOD:
                          return{
                            ...state,
                            DuplicatedOpenAlertEmptyCheckDayFood:false
                          }
                        case DISPATCH_LIST_CHECK_DATE_FOOD:
                          return{
                            ...state,
                            ListCheckDateFood:action.payload.arr
                          }
                        case DISPATCH_ADD_CHECK_DATE_FOOD:
                          return{
                            ...state,
                            ListCheckDateFood:state.ListCheckDateFood.concat(action.payload.item)
                          }
                          case DISPATCH_ADD_MANY_CHECK_DATE_FOOD:
                            toastSuccess('Chấm ăn ngày đã tạo thành công')
                            return{
                              ...state,
                              ListCheckDateFood:state.ListCheckDateFood.concat(action.payload.item)
                            }
                        case DISPATCH_ARRAY_STUDENT_NOT_EAT:                            
                          return{
                            ...state,
                            ListCheckDateFood:HandleUpdateArrCheckDayFoods(state.ListCheckDateFood,action.payload.arr)
                          }     
                          case DELETE_ITEM_CHECK_DATE_FOOD:
                            return{
                              ...state,
                              ListCheckDateFood:DeleteItemById(state.ListCheckDateFood,action.payload.item)
                            }                     
                          case UPDATE_ITEM_STUDENT_CHECK_DATE_FOOD:
                            let arrStudent = [action.payload.item.nameStudentNotEat.map(v=>v.idStudent),action.payload.item._id]
                            return{
                              ...state,
                              ListCheckDateFood:updateArrById(state.ListCheckDateFood,action.payload.item),
                              NumberStudentNotEat:arrStudent
                            }
                        case VIEW_CLOSE_CHECK_DAY_FOOD:
                          return{
                            ...state,
                            ViewOpenCheckDayFood:false
                          }
                        case VIEW_OPEN_CHECK_DAY_FOOD:
                          return{
                            ...state,
                            ViewOpenCheckDayFood:true
                          }
                        case DATE_NOW:                          
                          return{
                            ...state,
                            DateInput:action.payload.date
                          }
                          case DISPATCH_DATE_CHECKFOOD:
                            return{
                              ...state,
                              DateInput:action.payload.date
                            }
                          case DISPATCH_NUMBER_STUDENT_NOT_EAT:
                            let result = action.payload.arrStudent
                            let idStore= action.payload._idToStore                            
                             if(result.length>0){
                              return {
                                  ...state,
                                  NumberStudentNotEat:[result.map(v=>v.idStudent),idStore]
                              }
                            }else{
                              return{
                                ...state,
                                NumberStudentNotEat:[[],idStore]
                              }
                            }                            
                        case DISPATCH_ITEM_STUDENT_DATE_INPUT:
                          let ITEM = action.payload.item
                          let newArr = {
                            dateNow      : state.DateInput,
                            idYearSchool : ITEM.idYearSchool, 
                            idClass      : ITEM.idClass,
                            idBlockClass : ITEM.idBlockClass,
                            userId       : ITEM.userId,
                            idMenuWeekLy : ITEM.idMenuWeekLy,
                            students     : ITEM.students
                          }
                          //console.log(newArr)
                          return{
                            ...state,
                            ItemStudentDateInput:newArr
                          }
                        case UPDATE_CHECK_DATE_FOOD_SUCCESS:
                          toastSuccess(changeUpperCase(ALERT_UPDATE_CHECK_DATE_FOOD))
                          return{
                            ...state
                          }
                      case OPEN_CHECK_DAY_FOOD_AGAIN:
                        return{
                          ...state,
                          OpenAlertFormCheckDateFood:true
                        }
                        case CLOSE_CHECK_DAY_FOOD_AGAIN:
                          return{
                            ...state,
                            OpenAlertFormCheckDateFood:false
                          }
                      case SEND_IDS_TO_DB_ADD_EMPTY_NOT_EAT:
                        return{
                          ...state,
                          IDS:action.payload.IDS
                        }
                      case REGIS_SEVEN_DAY_TRUE:
                        return{
                          ...state,
                          RegisSevenDay:true
                        }
                      case REGIS_SEVEN_DAY_FALSE:
                        return{
                          ...state,
                          RegisSevenDay:false
                        }
                      case OPEN_ALERT_EMPTY_SELECT_REGIS_SEVEN_DAY:
                        return{
                          ...state,
                          OpenAlertRegisSevenDay:true
                        }
                      case CLOSE_ALERT_EMPTY_SELECT_REGIS_SEVEN_DAY:
                        return{
                          ...state,
                          OpenAlertRegisSevenDay:false
                        }
                      case DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY_TO_STORE:
                        return{
                          ...state,
                          ListRegisSevenDay:state.ListRegisSevenDay.concat(action.payload.item),
                        }
                      case DISPATCH_LIST_REGIS_SEVEN_TO_STORE:                       
                        return{
                          ...state,
                          ListRegisSevenDay:action.payload.arr
                        }         
                      case DISPATCH_UPDATE_REGIS_SEVEN_DAY:
                        let NewUpdateListRegisSevenDay= updateArrById(state.ListRegisSevenDay,action.payload.item)
                        let FindRegisSevenDay   = FindItemRegisSevenDay(NewUpdateListRegisSevenDay,state.ListStudentClassByYearSchool[0],Date.now())                         
                        return{
                          ...state,
                          ListRegisSevenDay:NewUpdateListRegisSevenDay,
                          ListRegisSevenDayByClass:FindRegisSevenDay[0].nameStudentEatSevenDay.map(v=>v),
                          FilterRegisSevenDayByClass:FindRegisSevenDay
                      }
                      case OPEN_UPDATE_REGIS_SEVEN_DAY:                       
                        return{
                          ...state,
                          OpenUpdateRegisSevenDay:true,
                          ListRegisSevenDay:state.ListRegisSevenDay,
                          ListRegisSevenDayByClass:(FindItemRegisSevenDay(state.ListRegisSevenDay,state.ListStudentClassByYearSchool[0],Date.now()))[0].nameStudentEatSevenDay.map(v=> {
                              if(!v.idStudent){
                                return v
                              }else{
                                return v.idStudent
                              }
                          }),
                          FilterRegisSevenDayByClass:FindItemRegisSevenDay(state.ListRegisSevenDay,state.ListStudentClassByYearSchool[0],Date.now())
                        }   
                      case DELETE_ITEM_REGIS_SEVEN_DAY:
                        return{
                          ...state,
                          ListRegisSevenDay:DeleteItemById(state.ListRegisSevenDay,action.payload.item),
                          ListRegisSevenDayByClass:DeleteItemById(state.ListRegisSevenDayByClass,action.payload.item)
                        }       
                      case CLOSE_UPDATE_REGIS_SEVEN_DAY:
                        return{
                          ...state,
                          OpenUpdateRegisSevenDay:false
                        } 
                    case DISPATCH_ARR_TO_UPDATE_REGIS_SEVEN_DAY:
                      return{
                        ...state,
                        ArrUpdateRegisSevenDay:action.payload.item
                      }
                    case UPDATE_REGIS_SEVEN_DAY_SUCCESS:
                      toastSuccess("Cập nhật thành công")
                      return{
                        ...state
                      }
                //checkdayfood
                // open alert delete regis seven day
                case OPEN_ALERT_DELETE_REGIS_SEVEN_DAY:
                  return{
                    ...state,
                    OpenAlertDeleteRegisSevenDay:true
                  }
                  case CLOSE_ALERT_DELETE_REGIS_SEVEN_DAY:
                    return{
                      ...state,
                      OpenAlertDeleteRegisSevenDay:false
                    }
                //open alert delete check date food
                case CLOSE_ALERT_DELETE_CHECK_DATE_FOOD:
                  return{
                    ...state,
                    OpenAlertDeleteCheckDateFood:false
                  }
                  case OPEN_ALERT_DELETE_CHECK_DATE_FOOD:
                  return{
                    ...state,
                    OpenAlertDeleteCheckDateFood:true
                  }
                case OPEN_UPDATE_LIST_STUDENT:
                  return{
                    ...state,
                    OpenUpdateListStudent:true
                  }
                case CLOSE_UPDATE_LIST_STUDENT:
                  return{
                    ...state,
                    OpenUpdateListStudent:false
                  }
              case ADD_STUDENT_AFTER:
                toastSuccess('Cập nhật thành công, hệ thống tự động đăng xuất, vui lòng đăng nhập lại')
                return{
                  ...state
                }
              case DELETE_STUDENT_NOW:
                toastSuccess('Cập nhật thành công, hệ thống tự động đăng xuất, vui lòng đăng nhập lại')
                return {
                  ...state
                }
                //get
              case DISPATCH_STUDENT_LEAVE_TEMPORATY_TO_STORE:
                return{
                  ...state,
                  StudentLeaveTemporaty:action.payload.item
                }
              case ADD_STUDENT_LEAVE_TEMPORATY_TO_STORE:
                return{
                  ...state,
                  StudentLeaveTemporaty:state.StudentLeaveTemporaty.concat(action.payload.item)
                }               
              case UPDATE_STUDENT_LEAVE_TEMPORATY:
                let findArr = DeleteItemById(state.StudentLeaveTemporaty,action.payload.item)
                return{
                  ...state,
                  StudentLeaveTemporaty:findArr
                }
              case UPDATE_STUDENT_LEAVE_TEMPORATY_AGAIN:
                toastSuccess('Lưu Thành công')
                return{
                  ...state,
                  StudentLeaveTemporaty:state.StudentLeaveTemporaty.concat(action.payload.item)
                }
              case GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW_TO_STORE:
                return{
                  ...state,
                  StudentLeaveTemporatyByClass:action.payload.item
                }
    default: return state      
  }
}

export default StudentReducer